.login.form--container {
    width: 25%;
    position: fixed;
    top: 55%;
    left: 40%;
    border: none;
    transform: translateY(-50%);
    min-height: 50%
}

fieldset.login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: none;
    border-radius: 1vh;
    margin-inline: 3px;
    margin-block: 0;
    padding-block: 0;
    padding-inline: 17px;
    max-width: 100%;
    min-width: fit-content;
}

/* 
p {
    margin-top: 5.3%;
} */

form.login::before {
    content: "";
    background-color: #fff;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    width: 2px;
    border-color: transparent;
    background-color: #ff94c8;
    z-index: -1;
}

form.login {
    background-image: linear-gradient(0deg, transparent 90%, #94c8ff 90%, #94c8ff 100%);
    background-size: 100% 23.4px;
    border-color: transparent;
    position: relative;

}

.login label#check {
    color: red;
}

.login.button--container {
    display: flex;
    justify-content: space-between;
    padding-inline: 3px;
    padding-bottom: 3px;
}

#login-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url("./assets/login.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 760px) {
    .login {
        margin-left: 0px;
    }
    form.login{
        border: 1px solid black;
        padding: 6px;
        border-radius: 15px;
        font-weight: bold;
        background-color: aliceblue;
    }
    #login-background {
        background-image: url("./assets/login.jpg");
        background-size: auto 100%;
        background-position: 0;

    }
    .container--login {
        margin-left: -20px;
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
    }
    fieldset.login{
        margin: 0;
        padding: 1px;
    }
    .container--login h1 {
        margin-left: -40px;
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
    }
    .login.form--container {
        display: flex;
        justify-content: center;
        width: 80%;
        left: auto;
        transform: translateY(-40%);
    }
}

@media (max-width: 350px) {
    .container--login h1 {
        margin-left: -20px;
        margin-top: -8px;
        display: flex;
        flex-wrap: nowrap;
    }

    form.login {
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: -40px;
        margin-left: -20px;
        border: 1px solid black;
        padding: 4px;
        border-radius: 15px;
    }
    fieldset.login{
        margin: 0;
        padding: 1px;
    }
    .login.form--container {
        transform: translateY(-25%);
        display: flex;
        justify-content: center;
        width: auto;
        left: auto;
    }

}

.register--button,
.login--button {
    background-color: #80cfa5;
    border: none;
    color: white;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    margin: 5px;
    width: 39%;
}

.register--button--cancel {
    background-color: #cf8780;
    border: none;
    color: white;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    margin: 5px;
}

.register--button:hover {
    background-color: #6cb08c;
    color: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.login--button:hover {
    background-color: #6cb08c;
    color: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.register--button--cancel:hover {
    background-color: #8b5a56;
    ;
    color: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}