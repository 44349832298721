article.profile--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

section.friends {
    margin-top: 3px;
    border: 2px solid black;
    max-height: 55vh;
    overflow-y: auto;
}

section.tags {
    background-color: rgba(128, 207, 165, 0.2);
    /* Add transparency */
    backdrop-filter: blur(10px);
    /* Add blur for frosted glass effect */
    border-radius: 20px;
    margin: 3px;
    padding-inline: 5px;
    padding: 15px;
    border: 2px solid black;
    max-height: 55vh;
    overflow-y: auto;
}

.friend--box {
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 1vh;
    margin: 3px;
    padding-inline: 5px;
    backdrop-filter: blur(10px);
    /* Add blur for frosted glass effect */
    background-color: rgba(255, 255, 255, 0.7);
}

section.friends div button {
    border-radius: 3vh;
    background-color: white;
    margin: 5px;
}

form.friend {
    border: 2px solid black;
}

.friend fieldset {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 1vh;
    margin: 3px;
    padding-inline: 5px;
}

form.tag,
form.friend {
    background-color: rgba(128, 207, 165, 0.2);
    /* Add transparency */
    backdrop-filter: blur(10px);
    /* Add blur for frosted glass effect */
    border-radius: 1vh;
    margin: 3px;
    padding-inline: 5px;
}

fieldset.tag,
fieldset.email {
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 1vh;
    margin: 3px;
    padding-inline: 5px;
    backdrop-filter: blur(10px);
    /* Add blur for frosted glass effect */
    background-color: rgba(255, 255, 255, 0.7);
}

.friend .button--container {
    display: flex;
    justify-content: space-between;
}

.friend.form--field {
    margin-block: 3px;
}

.tag--box {
    display: flex;
    backdrop-filter: blur(10px);
    /* Add blur for frosted glass effect */
    background-color: rgba(255, 255, 255, 0.7);
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 1vh;
    margin: 3px;
    padding-inline: 5px;
}

section.tags div button {
    border-radius: 3vh;
    background-color: white;
    margin: 5px;
}

.tools--container,
.friends--container {
    text-align: center;
}

.tools--container form.tag {
    border: 2px solid black;
}

.tools--container .tag fieldset {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 1vh;
    margin: 3px;
    padding-inline: 5px;
}

.tag .button--container {
    display: flex;
    justify-content: space-between;
}

.tag.form--field {
    margin-block: 3px;
}

.tag--box .tag fieldset {
    display: flex;
    justify-content: space-between;
    border: none;
    padding: 0;
    margin: 0;
}

.tag--box .tag input {
    display: flex;
    font-weight: bold;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

fieldset.tag,
.tag.form--field {
    padding: 0;
}

.background--container {
    background-image: url("./assets/foggy-glass.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1000;
}

section.tags--container {
    display: block;

}

button.profile-button {
    background-color: #80cfa5;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    margin: 5px;
    width: 70%;
}

button.profile-button-cancel {
    background-color: #cf8780;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    margin: 5px;
}

button.profile-button:hover {
    background-color: #6cb08c;
    color: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

button.profile-button-cancel:hover {
    background-color: #8b5a56;
    ;
    color: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.search-input {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 20px;
    width: 100%;
    max-width: 300px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    outline: none;
    background-color: #f8f8f8;
    box-sizing: border-box;
}

.search-input:focus {
    border-color: #80cfa5;
    box-shadow: 0 0 8px rgba(128, 207, 165, 0.3);
}
