.comment.form--container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

form.comment {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
}

textarea {
    resize: none;
}

.comments--container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.comment--container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.comment--container:nth-child(even) .comment {
    background-color: #dafdeb;
    border-radius: 5px;
    padding: 5px;
}

.comment--container:nth-child(odd) .comment {
    background-color: #9bd5b6;
    border-radius: 5px;
    padding: 5px;
}

.commenter--and--button--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comment--button--container {
    display: flex;
    gap: 5px;
}

.comment--label {
    font-weight: bold;
    margin-bottom: 5px;
}

.comment.button--container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.commentFormSubmit--button,
.commentFormCancel--button {
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.commentFormSubmit--button {
    background-color: #80cfa5;
    color: white;
}

.commentFormCancel--button {
    background-color: #cf8780;
    color: white;
}

.commentFormSubmit--button:hover {
    background-color: #6cb08c;
}

.commentFormCancel--button:hover {
    background-color: #8b5a56;
}